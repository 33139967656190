.tokoscholar-users-data {
  padding: 13px;
  &__container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  &__item {
  }
  &__card {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

    &__head {
      display: flex;
      padding: 10px 11px 7.5px;
      border-bottom: 1px solid #e5e4e4;
      gap: 10px;
      align-items: center;
      position: relative;
      &__image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;
        // position: relative;
        // object-fit: contain;
        // object-position: bottom;
        img {
          //   position: absolute;
          //   top: 0;
          //   left: 0;
          width: 100%;
          height: auto;
        }
      }
      &__identity {
        display: flex;
        flex-direction: column;
        gap: 2px;
        p {
          margin: 0;
        }
        &__name {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          //   line-height: 12px;
          //   text-align: center;

          color: #000000;
        }
        &__email {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          //   line-height: 9px;
          //   text-align: center;

          color: #484848;
        }
        &__phone {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 11px;
          //   line-height: 8px;

          color: #000000;
        }
        &__dropdown {
          width: 3px;
          position: absolute;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }
      }

      .dropdown {
        align-self: flex-start;
      }
      &__icon {
        &::after {
          display: none !important;
        }
      }
    }
    &__body {
      display: flex;
      flex-direction: column;
      padding: 8.5px 11px 18px;
      &__item {
        display: flex;
        justify-content: space-between;
        p {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 11px;
          color: #000000;
          margin: 4px;
        }
      }
    }
  }
}
