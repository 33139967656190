.collaboration-menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
}

.collaboration-limit {
  max-width: 75px;
}
