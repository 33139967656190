.userlist-menu__top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 30px;
}

.userlist-limit {
  max-width: 75px;
}
