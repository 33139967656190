.kanban_board {
  width: 100%;
  height: 50vh;
  overflow: auto;
  position: relative;
  background: #F4F4F4;
  z-index: 0;
  .kanban_board__content {
    display: flex;
    align-items: stretch;
    min-width: fit-content;
    gap: 0;
    min-height: 100%;
    .kanban_board__content__item_active {
      min-width: 280px;
      min-height: 100vh;
      // padding: 60px 0 0 0;
      border-left: 0.5px solid #A8A8A8;
      border-right: 0.5px solid #A8A8A8;
      transition: all 0.3s;
      position: relative;
    }
    .kanban_board__content__item_inactive {
      min-width: 80px;
      transition: all 0.3s;
      position: relative;
    }
    .kanban_board__content__header_active {
      position: sticky;
      top: 0;
      width: 279px;
      height: 50px;
      padding: 15px;
      background: #F4F4F4;
      text-overflow: ellipsis;
      overflow: hidden;
      z-index: 1;
      cursor: pointer;
    }
    .kanban_board__content__header_inactive {
      position: sticky;
      top: 0;
      padding-top: 5vh;
      height: 100%;
      width: 80px;
      background: #F4F4F4;
      text-overflow: ellipsis;
      overflow: hidden;
      z-index: 1;
      border: 1px solid #A8A8A8;
      cursor: pointer;
      .kanban_board__content__header_inactive__content {
        transform: rotate(90deg);
      }
    }
    .kanban_board__content__header_active:hover, .kanban_board__content__header_inactive:hover {
      transition: all 0.3s;
      background: #5d78ff;
      color: #FFF;
      border: none;
    }
    .kanban_board__content__header_shadow {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .kanban_board__content__item {
      position: absolute;
      width: calc(100% - 20px);
      transition: all 0.3s;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-width: .1px;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      height: 120px;
      margin: 10px;
      padding: 8px;
      font-family: 'Poppins';
      .kanban_board__content__item__date {
        font-style: normal;
        font-weight: 400;
        font-size: 8px;
        line-height: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .kanban_board__content__item__tag {
        max-width: 100px;
        padding: 3px;
        border-width: .1px;
        border-style: solid;
        border-radius: 4px;
        font-size: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .kanban_board__content__item__title {
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
        color: #000000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .kanban_board__content__item__desc {
        font-size: 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 30px;
      }
      .kanban_board__content__item__left_prefix {
        font-size: 8px;
        position: absolute;
        bottom: 5px;
        left: 5px;
        padding: 5px;
        border-radius: 5px;
        border-width: .6px;
        border-style: solid;
        border-color: #d3d3d3;
      }
      .kanban_board__content__item__expiry_date {
        font-size: 8px;
        position: absolute;
        bottom: 5px;
        right: 5px;
        padding: 5px;
        border-radius: 5px;
      }
      .kanban_board__content__item__expiry_date__success {
        background-color: #A0FF9E;
      }
      .kanban_board__content__item__expiry_date__warning {
        background-color: #FCFF76;
      }
      .kanban_board__content__item__expiry_date__danger {
        background-color: #FFB69E;
      }
      .kanban_board__content__item__expiry_date__disable {
        background-color: #d3d3d3;
      }
    }
    .kanban_board__content__item:hover {
      border-width: 2px;
    }
    .kanban_board__content__item_hoverable:hover {
      cursor: grab;
    }
    .kanban_board__content__item_hoverable:active {
      cursor: grabbing;
    }
    .kanban_board__content__item_disable:hover {
      cursor: not-allowed;
    }
  }
  .kanban_board_loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: rgba(255,255,255,.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.path-1 {
  opacity:1;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:1.49300182;stroke-opacity:1
}
.path-2 {
  fill:none;fill-rule:evenodd;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1
}
.path-3 {
  opacity:1;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:2.62047124;stroke-opacity:1
}
.path-4 {
  fill:#000000;fill-rule:evenodd;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1
}
.path-5 {
  fill:#000000;fill-rule:evenodd;stroke:#000000;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1
}
.circle-1 {
  opacity:1;fill:#000000;fill-opacity:1;stroke:#000000;stroke-width:1.18611968;stroke-opacity:1
}