.error {
  border: 2px solid #ff6565 !important;
}

.error-message {
  color: #ff6565;
  padding: 0.5em 0.2em;
  // height: 1em;
  // position: absolute;
  font-size: 1.2em;
}

.filter-price__divider {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 0;
  padding-right: 0;

  p {
    text-align: center;
    line-height: 35px;
    margin-bottom: 0;
  }
}

.bookingtype-menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
}

.bookingtype-limit {
  max-width: 75px;
}

.price-type__container-box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
}
