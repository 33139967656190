.helpdesk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  .helpdesk__topbar {
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    padding: 15px 0;
    .helpdesk__topbar__menu {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 21px;
      color: #012B81;
      text-decoration: none;
    }
  }
  .helpdesk__banner {
    background-image: url("../../../../../public/media/helpdesk/banner.svg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 416px;
    width: 100%;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    padding: 25px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
  }
  .helpdesk-wrapper {
    width: 90%;
    @media screen and (min-width: 1280px) {
      width: 1180px;
    }
  }
  .helpdesk-card {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    width: 100%;
    gap: 30px;
    .helpdesk-card__item {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      text-align: center;
      color: #000000;
      padding: 70px 15px;
      border-radius: 20px;
      transition: all 0.3s;
      .helpdesk-card__item__title {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
      }
      .helpdesk-card__item__desc {
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .helpdesk-card__item:hover {
      background: rgba(0,0,0,.1);
    }
    .helpdesk-card__item--disabled {
      background: rgba(0,0,0,.1);
      cursor: not-allowed;
    }
  }

  .helpdesk-faq {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .helpdesk-question {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #59AEC1;
      padding: 20px 15px;
      cursor: pointer;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #000000;
  
      .helpdesk-question__icon {
        transition: all 0.3s;
      }
    }
    .helpdesk-question-active {
      border-radius: 10px 10px 0 0;
    }
    .helpdesk-question-inactive {
      border-radius: 10px;
    }
    .helpdesk-answer {
      border: 1px solid #59AEC1;
      border-top: none;
      border-radius: 0 0 10px 10px;
      padding: 20px 15px;
      padding: 20px 15px;
      transition: all 0.3s;
    }
  }

  .helpdesk-form {
    margin-top: 40px;
  }
}
