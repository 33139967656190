.title-card {
    line-height: 20px;
    margin-left: 12px;
}

.card-anchor {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-decoration: none;
    color: #2DA602;

    &:hover {
        color: #4dc521;
    }
}