//
// Users
//




.kt-widget.kt-widget--users {
    .kt-widget__item {
        display: flex;
        margin: 1.3rem 0 2.3rem 0;

        .kt-media img {
            width: 100%;
            max-width: 43px;
            height: 43px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .kt-widget__info {
            padding-top: 0.1rem;
            flex: 3;
            margin-left: 1rem;

            .kt-widget__section {
                display: flex;
                align-items: center;

                .kt-widget__username {
                    font-size: 1.1rem;
                    color: kt-base-color(label, 4);
                    font-weight: 500;

                    &:hover {
                        color: kt-brand-color();
                        transition: color 0.3s ease;
                    }
                }

                .kt-badge {
                    margin-left: 0.5rem;
                }
            }

            .kt-widget__desc {
                display: block;
                color: kt-base-color(label, 2);
                font-weight: 500;
            }
        }

        .kt-widget__action {
            text-align: right;
            padding-top: 0.2rem;
            flex: 1.1;

            .kt-widget__date {
                display: block;
                color: kt-base-color(label, 2);
                font-weight: 500;
            }
        }
    }
}

@include kt-tablet-and-mobile {
    .kt-widget.kt-widget--users {
        .kt-widget__item {
            .kt-widget__info {
                flex: 1.5;
            }

            .kt-widget__action {
                flex: 1;
            }
        }
    }
}

.new-version-user-action-btn {
    border: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 50%;
    transition: all 0.3s;
}
.new-version-user-action-btn-none {
    span {
        color: rgba(194, 194, 194, 0.5);
    }
    background: rgba(194, 194, 194, 0.4);
}
.new-version-user-action-btn-none:hover {
    span {
        color: rgba(194, 194, 194, 1);
    }
    background: rgba(194, 194, 194, 0.6);
}
.new-version-user-action-btn-pending {
    span {
        color: rgba(249, 151, 5, 0.5);
    }
    background: rgba(249, 151, 5, 0.4);
}
.new-version-user-action-btn-pending:hover {
    span {
        color: rgba(249, 151, 5, 1);
    }
    background: rgba(249, 151, 5, 0.6);
}
.new-version-user-action-btn-approve {
    span {
        color: rgba(96, 166, 41, 0.5);
    }
    background: rgba(96, 166, 41, 0.4);
}
.new-version-user-action-btn-approve:hover {
    span {
        color: rgba(96, 166, 41, 1);
    }
    background: rgba(96, 166, 41, 0.6);
}
.new-version-user-action-btn-reject {
    span {
        color: rgba(215, 39, 39, 0.4);
    }
    background: rgba(215, 39, 39, 0.4);
}
.new-version-user-action-btn-reject:hover {
    span {
        color: rgba(215, 39, 39, 1);
    }
    background: rgba(215, 39, 39, 0.6);
}

.new-version-kyc-action {
    border: transparent;
    transition: all 0.3s;
}
.new-version-kyc-action-none { background: rgba(194, 194, 194, 0.8); }
.new-version-kyc-action-none:hover { background: rgba(194, 194, 194, 1); }
.new-version-kyc-action-pending { background: rgba(249, 151, 5, 0.8); }
.new-version-kyc-action-pending:hover { background: rgba(249, 151, 5, 1); }
.new-version-kyc-action-approve { background: rgba(96, 166, 41, 0.8); }
.new-version-kyc-action-approve:hover { background: rgba(96, 166, 41, 1); }
.new-version-kyc-action-reject { background: rgba(215, 39, 39, 0.8); }
.new-version-kyc-action-reject:hover { background: rgba(215, 39, 39, 1); }

.new-version-kyc-table-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #434343;
}

.new-version-row-signal {
    gap: .6px
}
.new-version-row-signal-1 {
    width: 8px;
    height: 8px;
}
.new-version-row-signal-2 {
    width: 8px;
    height: 16px;
}
.new-version-row-signal-3 {
    width: 8px;
    height: 26px;
}

.new-version-row-signal-none {
    border: 3px solid rgba(194, 194, 194, 0.8);
}
.new-version-row-signal-low {
    border: 3px solid rgba(96, 166, 41, 0.8);
}
.new-version-row-signal-low-fill {
    // border: 3px solid rgba(96, 166, 41, 0.8);
    background: rgba(96, 166, 41, 0.8);
}
.new-version-row-signal-medium {
    border: 3px solid rgba(249, 151, 5, 0.8);
}
.new-version-row-signal-medium-fill {
    // border: 3px solid rgba(249, 151, 5, 0.8);
    background: rgba(249, 151, 5, 0.8);
}
.new-version-row-signal-high {
    border: 3px solid rgba(215, 39, 39, 0.8);
}
.new-version-row-signal-high-fill {
    // border: 3px solid rgba(215, 39, 39, 0.8);
    background: rgba(215, 39, 39, 0.8);
}

.new-version-detail-user-wrapper {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 15px;
}
.new-version-detail-avatar {
    width: 62px;
    height: 62px;
    background: #D9D9D9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    padding: 15px;
}
.new-version-detail-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
}
.new-version-detail-email {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #000000;
}
.new-version-detail-label {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
}
.new-version-detail-value {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
}

.new-version-legend-color-box {
    width: 15px;
    height: 15px;
    border-radius: 5px;
}
.new-version-legend-color-box-pending { background: rgba(249, 151, 5, 0.8); }
.new-version-legend-color-box-approve { background: rgba(96, 166, 41, 0.8); }
.new-version-legend-color-box-reject { background: rgba(215, 39, 39, 0.8); }
.new-version-legend-color-box-none { background: rgba(194, 194, 194, 0.8); }

.new-version-position-relative {
    position: relative;
    .new-version-position-relative--wrapper-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        min-height: 90%;
        border-radius: 15px 15px 0 0;
        cursor: pointer;
        z-index: 1;
        opacity: 0;
    }
    .new-version-position-relative--wrapper-content:hover {
        opacity: 1;
        background: rgb(0,0,0);
        background: linear-gradient(183deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%);
    }
    // .new-version-position-relative--wrapper-content:active {
    //     pointer-events: none;
    // }
    .new-version-position-relative--content {
        overflow: hidden;
        border-radius: 15px;
    }
}
.new-version-download-button {
    z-index: 1;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    // pointer-events: visible;
}