//Middleware project
@import './_homemiddleware.scss';
@import './_settingmiddleware.scss';
@import './clientmiddleware.scss';
@import './usermiddleware.scss';

.sort-button {
  border: none;
  background: transparent;
}

.center {
  text-align: center;
}

.all-center {
  vertical-align: middle !important;
  text-align: center;
}

.row-search {
  padding-bottom: 2em;
}

@media (max-width: 1024px) {
  .row-search {
    margin-top: 5em !important;
  }
}

.head {
  margin-top: -4px;
}

@media screen and (max-width: 922px) {
  .head {
    margin-top: 20px;
  }
}

.margin-botHalf {
  margin-bottom: 0.5em;
}

.left-modal {
  text-align: left;
  display: grid;
}

.no-data {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #b5b2b2;
  font-size: 16px;
}

.captcha-position {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.required {
  color: red;
  font-size: 12px;
  padding: 5px;
}

.input-otp {
  border: none;
  width: 15.5ch;
  margin-bottom: 5px;
  background: repeating-linear-gradient(90deg,
      dimgrey 0,
      dimgrey 1.4ch,
      transparent 0,
      transparent 2.2ch) 0 100%/80% 2px no-repeat;
  color: dimgrey;
  font: 5ch consolas, monospace;
  letter-spacing: 1.25ch;

  &:focus {
    outline: none;
    color: #000;
    background: repeating-linear-gradient(90deg,
        #1aa64a 0,
        #1aa64a 1.4ch,
        transparent 0,
        transparent 2.2ch) 0 100%/80% 2px no-repeat;
  }
}

// .status {
//     padding: 0 5px;
//     display: inline-block;
//     text-transform: uppercase;
//     border-radius: 4px;
//     color: #000;
//     font-weight: bold;

//     &.approve {
//       background-color: #00cc00;
//       // background-color: #36c6d3;
//     }

//     &.one {
//       background-color: #ff8000;
//       // background-color: #337ab7;
//     }

//     &.two {
//       background-color: #f1c40f;
//     }

//     &.three {
//       background-color: #ed6b75;
//     }
// }

.link-action {
  cursor: pointer;
  color: #000;
  padding: 2px;

  &:hover {
    color: darkcyan;
  }
}

.font-delete {
  justify-content: center;
  display: flex;
  font-size: 15px;
  font-weight: 400;
}

.tableheh {
  border-bottom: 2px solid #ebedf2 !important;
  border-top: 1px solid #eff0f1 !important;
}

.kt-portlet-top {
  margin-top: -15px;
}

.pddmdForce {
  padding: 20px 20px !important;
}

.pddlessForce {
  padding: 0 !important;
}

.paddupgrade {
  padding: 10px 10px;
  margin-left: 10px;
}

.label-dot {
  display: inline-block;
  font-size: 0 !important;
  vertical-align: middle;
  text-align: center;
  line-height: 6px;
  min-height: 6px;
  min-width: 6px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

.success {
  background-color: #28a745;
}

.danger {
  background-color: #dc3545;
}

.totalAmmount {
  font-size: 12px;
  font-weight: 500;
  color: #181c32;
}

.pad-10-20 {
  padding: 10px 8px !important;
}

.font-8rem {
  font-size: 0.8rem !important;
}

.font-2rem {
  font-size: 2rem;
}

.custom-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.custom-flex-row {
  display: flex;
  flex-direction: row;
}

.custom-flex-row-center {
  @extend .custom-flex-row;
  gap: 1rem;
  align-items: center;
}
.custom-flex-row-center-end {
  @extend .custom-flex-row;
  gap: 1rem;
  align-items: flex-end;
}
.custom-flex-row-center-justify-end {
  @extend .custom-flex-row;
  gap: 1rem;
  justify-content: end;
}
.custom-flex-col-center {
  @extend .custom-flex-col;
  align-items: center;
}
.custom-flex-col-center-center {
  @extend .custom-flex-col;
  justify-content: center;
  align-items: center;
}
.custom-flex-col-space-between {
  @extend .custom-flex-col;
  justify-content: space-between;
}

.custom-flex-row-space-between {
  @extend .custom-flex-row;
  justify-content: space-between;
}

.custom-flex-col-space-between {
  @extend .custom-flex-col;
  justify-content: space-between;
}
.custom-flex-row-center {
  @extend .custom-flex-row;
  align-items: center;
}
.custom-flex-row-space-between-center {
  @extend .custom-flex-row;
  align-items: center;
  justify-content: space-between;
}
.custom-flex-col {
  display: flex;
  flex-direction: column;
}
.custom-filter-list {
  list-style-type: none;
  margin: 1rem 0 1rem 0;
  padding: 0;
  li {
    float: left;
    border-left: 1px solid #B7B7B7;
    border-top: 1px solid #B7B7B7;
    border-bottom: 1px solid #B7B7B7;
    padding: 15px;
    font-family: Poppins;
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
    transition: all 0.3s;
  }
  &__text_active {
    color: #FFFFFF !important;
  }
  &__active {
    color: #FFFFFF !important;
    font-weight: 700;
    background: #0B93CD !important;
  }
  &__inactive {
    color: #000000;
    font-weight: 400;
  }
  &__last-item {
    border-right: 1px solid #B7B7B7;
  }
}

.btn-register-home {
  color: #fff !important;
  width: auto;
  height: 38px;
  font-size: 0.8rem;
  left: 0px;
  top: 230px;
  background: #1aa64a;
  box-shadow: 0px 2px 4px rgba(26, 166, 74, 0.1);
  border-radius: 4px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0px;
  margin-left: 5px;

  @media screen and (max-width: 991px) {
    margin-top: 10px;
  }

  @media screen and (max-width: 500px) {
    // margin-left: 10px;
  }

  &:hover {
    color: #eaecef;
  }
}

.btn-event-home {
  margin-left: 0;
  width: auto;
  height: 38px;
  font-size: 0.8rem;
  background: #f7f7f7;
  border: 1px solid #eaecef;
  border-radius: 4px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  color: #535353;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0px;
  margin-left: 5px;

  @media screen and (max-width: 991px) {
    margin-top: 10px;
  }
}

.btn-event-home-white {
  margin-left: 0;
  width: auto;
  height: 38px;
  font-size: 0.8rem;
  background: #ffffff;
  border: 1px solid #eaecef;
  border-radius: 4px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  color: #535353;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0px;
  margin-left: 5px;

  @media screen and (max-width: 991px) {
    margin-top: 10px;
  }
}

.btn-event-home-border-green {
  margin-left: 0;
  width: auto;
  height: 38px;
  font-size: 0.8rem;
  background: #ffffff;
  border: 1px solid #1aa64a;
  border-radius: 4px;
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  color: #535353;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0px;
  margin-left: 5px;

  @media screen and (max-width: 991px) {
    margin-top: 10px;
  }
}

.img-th {
  height: 48px;
  width: 48px;
  background-size: cover;
  margin-left: 5px;
  margin-right: 5px;
}

.img-bn {
  height: 100%;
  width: 150px;
  background-size: cover;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-action {
  cursor: pointer;
  padding: 2px 4px;
  border-radius: 4px;
}

.dropzone {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: start;
  border: 0px transparent;
  border: 1px solid #e2e5eb;
  padding: 15px 15px;
  flex-wrap: wrap;
  -webkit-box-shadow: -1px 3px 7px 0px rgba(197, 193, 193, 0.44);
  -moz-box-shadow: -1px 3px 7px 0px rgba(197, 193, 193, 0.44);
  box-shadow: -1px 3px 7px 0px rgba(197, 193, 193, 0.44);
}

.setTitleDropzone {
  color: gray;
  font-style: bold;
  font-weight: 500;
  justify-content: start;
  align-items: center;
  text-align: center;

  span {
    cursor: pointer;
    color: #ab92ef;
  }
}

.setSubTitleDropzone {
  margin-bottom: 0px;
  margin-top: -12px;
  font-weight: 500;
  justify-content: start;
  align-items: center;
  text-align: start;
  color: gray;
}

.img-preview {
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: contain;
}

.width-date {
  width: 100% !important;
}

.desc-length-position {
  text-align: right;
}

.react-datepicker-wrapper {
  display: block;
}

.radius-left {
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.radius-remove {
  border-radius: 0px;
}

.radius-right {
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.img-loading {
  object-fit: contain;
  height: 100px;
  width: 100%;
}

.container-button {
  // margin-left: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  .grouping-button {
    width: 32% !important;
    display: flex;
    margin-right: 10px;
    margin-top: 20px;

    .btn-in-group {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      padding: 10px 10px;
    }

    .btc-s {
      background: #2da602;
      border-radius: 8px 0px 0px 8px;
      color: #ffffff;
      text-transform: uppercase;
    }

    .btn-outline {
      color: #2da602;
      border: 1px solid #2da602;
      box-sizing: border-box;
      border-radius: 0px 8px 8px 0px;
    }

    .btn-outline:hover {
      background: #2da602;
      color: #ffffff;
    }

    .btn-without-border-lr {
      border-radius: 0px 0px 0px 0px;
      border-left: transparent 0px;
      border-right: transparent 0px;
    }
  }

  .grouping-button-new {
    display: flex;

    .btn-in-group {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      padding: 10px 10px;
    }

    .btc-s {
      background: #2da602;
      border-radius: 8px 0px 0px 8px;
      color: #ffffff;
      text-transform: uppercase;
    }

    .btn-outline {
      color: #2da602;
      border: 1px solid #2da602;
      box-sizing: border-box;
      border-radius: 0px 8px 8px 0px;
    }

    .btn-outline:hover {
      background: #2da602;
      color: #ffffff;
    }

    .btn-without-border-lr {
      border-radius: 8px 0px 0px 8px;
      border-right: transparent 0px;
    }

    .btn-without-border-radius {
      border-radius: 0;
      border-right: transparent 0px;
    }
  }

  .grouping-button-small {
    width: auto !important;
    display: flex;
    margin-right: 5px;
    margin-top: 20px;

    .btn-in-group {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      padding: 10px 10px;
    }

    .btc-s {
      background: #2da602;
      border-radius: 8px 0px 0px 8px;
      color: #ffffff;
      text-transform: uppercase;
    }

    // .btc-s:hover{
    //     color: #2DA602;
    //     border: 1px solid #2DA602;
    //     background: #fff;
    // }
    .btn-outline {
      color: #2da602;
      border: 1px solid #2da602;
      box-sizing: border-box;
      border-radius: 0px 8px 8px 0px;
    }

    .btn-outline:hover {
      background: #2da602;
      color: #ffffff;
    }

    .btn-without-border-lr {
      border-radius: 0px 0px 0px 0px;
      border-left: transparent 0px;
      border-right: transparent 0px;
    }
  }
}

.pagination-data {
  .pagination {
    .page-item {
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .page-link {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: #838383;
        background-color: transparent;
        border-color: transparent;
      }
    }

    .active {
      margin-left: 5px;
      margin-right: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      .page-link {
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
        background-color: transparent;
        border-color: transparent;
      }
    }
  }
}

.peruri {
  font-weight: 600;
  color: #005398;
}

.asliri {
  font-weight: 600;
  color: #b80f0a;
}

.input-date {
  background: #ffffff;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 0.65rem 1rem;
  width: 100%;
  height: calc(1.5em + 1.3rem + 3px);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.mri-input {
  margin-right: 5px !important;
}

.remove-frame-button {
  padding: 0 !important;
  margin: 0;
}

.dasboard-content-title-card {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
}

.dasboard-content-value-card {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  line-height: 48px;
  color: #272727;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  cursor: pointer;
}

.dashboard-column-transaction-no {
  max-width: 150px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  cursor: pointer;
  text-align: right;
}

.allowed {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 8px;
  color: rgb(165, 173, 186) !important;
  font-weight: 500;
}

.add-selection {
  font-size: 13px;
  color: #000;
  font-weight: 400;
  margin-right: 1.5rem;
}

.font-16 {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.table-user-kyc-responsive {
  max-height: 1200px;
  overflow-y: auto;
}

.table-user-kyc {
  width: 100%;
}

.load-more-text {
  cursor: pointer;
  font-size: 1rem;
  color: var(--info);
  margin-top: 1.25rem;

  &--disabled {
    color: var(--secondary);
    cursor: default;
  }
}

.multiply-form-label {
  background: #F1F1F1;
  border: 1px solid #E3E3E3;
  padding: 9px;

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #B7B7B7;
}
.dashboard-transaction-body-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  border-bottom: 1px dashed #5A5A5A;
}
.dashboard-transaction-body-info {
  font-family: 'Poppins';
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  &__normal {
    font-weight: 400;
    color: #000000;
  }
  &__bold {
    font-weight: 600;
    color: #000000;
  }
  &__success {
    font-weight: 700;
    color: #2DA602;
  }
}

.sidebar-bottom {
  bottom: 0;
  width: 100%;
  position: absolute;
  padding: 10px;
  background: #1E1E2D;
  box-shadow: 0 -0.5rem 1rem rgba(4, 17, 41, 0.15)!important;
  overflow: hidden;
  .sidebar-bottom__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    padding: 5px;
    gap: 5px;
    border-radius: 10px;
    transition: all 0.3s;
  }
  .sidebar-bottom__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    transition: all 0.3s;
    width: 200px;
    .sidebar-bottom__content__avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: #000000;
      transition: all 0.3s;
      overflow: hidden;
    }
    .sidebar-bottom__content__sub-inactive {
      width: 30px;
      height: 30px;
      background: #D9D9D9;
      border-radius: 50%;
    }
    .sidebar-bottom__content__sub-active {
      width: 35px;
      height: 35px;
      background: #AB2525;
      border-radius: 6px;
    }
    .sidebar-bottom__content__infos {
      display: flex;
      flex-direction: column;
      gap: 3px;
      font-family: 'Poppins';
      font-style: normal;
      color: #FFFFFF;
      max-width: 140px;
      .sidebar-bottom__content__infos__name {
        font-weight: 500;
        font-size: 12px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
        display: inline-block;
      }
      .sidebar-bottom__content__infos__title {
        font-weight: 300;
        font-size: 12px;
        line-height: 10px;
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
    }
  }
  .sidebar-bottom__ellipsis {
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background: none;
    border: none;
    transition: all 0.3s;
  }
  .sidebar-bottom__ellipsis:hover {
    background: #1b1b28;
  }
}

.reusable-table-header-sort {
  cursor: pointer;
  .reusable-table-header-sort-trigger {
    transition: all 0.3s;
    transform: rotate(90deg);
    opacity: 0;
    visibility: hidden;
  }
  .reusable-table-header-sort-trigger--asc{
    transform: rotate(-90deg);
    opacity: 1;
    visibility: visible;
  }
  .reusable-table-header-sort-trigger--desc{
    transform: rotate(90deg);
    opacity: 1;
    visibility: visible;
  }
}

.user-card-file-info {
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 10px;
}

.smooth-transition {
  transition: all 0.3s;
}

.rotation-wrapper-outer {
  display: table;
}
.rotation-wrapper-inner {
  padding: 50% 0;
  height: 0;
}
.element-to-rotate {
  display: block;
  transform-origin: top left;
  /* Note: for a CLOCKWISE rotation, use the commented-out
     transform instead of this one. */
  transform: rotate(-90deg) translate(-100%);
  /* transform: rotate(90deg) translate(0, -100%); */
  margin-top: -50%;

  /* Not vital, but possibly a good idea if the element you're rotating contains
     text and you want a single long vertical line of text and the pre-rotation
     width of your element is small enough that the text wraps: */
  white-space: nowrap;

  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  color: #FFFFFF;
}

.row-wrapper {
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #FFFFFF;
  overflow: hidden;
  position: relative;
  min-height: 70px;
}
.row-wrapper__left {
  border-radius: 5px 0 0 5px;
}
.row-wrapper__right {
  border-radius: 0 5px 5px 0;
}

.border-bottom-1 {
  padding: 10px 0 10px 0;
  border-bottom: 0.5px solid #EAEAEA;
}

.timeline {
  padding-left: 2rem;
  overflow: auto;
  max-height: 550px;
  .timeline-with-icons {
    padding-left: 2rem;
    border-left: 1px dotted hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
    .timeline-item {
      position: relative;
    }
    
    .timeline-item:after {
      position: absolute;
      display: block;
      top: 0;
    }
    
    .timeline-icon {
      position: absolute;
      left: -42px;
      background-color: hsl(217, 88.2%, 90%);
      color: hsl(217, 88.8%, 35.1%);
      border-radius: 50%;
      height: 31px;
      width: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  
    .timeline-subtitle {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
    }
}
}
@for $i from 1 through 99 {
  .w-#{$i} {
    width: $i +'%';
  }
  .h-#{$i} {
    height: $i +'%';
  }
  .border-spacing-#{$i} {
    border-spacing: $i + px;
  }
}

.cursor-pointer {
  cursor: pointer;
}
.custom-btn {
  padding: 4px !important;
  font-size: 11px;
}
.text-right {
  text-align: right;
}

.react-datepicker-popper {
  z-index: 2;
}