.users_grid {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(24.25rem,1fr));
  grid-gap: 1rem;
  gap: 1rem;
  &__child{
    text-decoration: none;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 20px;
    &__top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    &__image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    &__title {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 12px;
      text-align: center;
      color: #000000;
    }
    &__desc {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: .8rem;
      line-height: 9px;
      text-align: center;
      color: #484848;
    }
    &__info_label {
      margin-top: 20px;
      margin-bottom: 20px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: .8rem;
      line-height: 8px;
      text-align: center;
      color: #000000;
    }
    &__infos{
      width: 100%;
      padding: 15px 0 0 0;
      border-top: 1px solid #e5e2e2;
      display: flex;
      flex-direction: column;
      gap: 15px;
      &__info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: .7rem;
        line-height: 8px;
        color: #000000;
        padding: 0 0 15px 0;
        border-bottom: 1px solid #e5e2e2;
      }
    }
  }
}

.popover-body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  &__child {
    padding: 0 20px 0 5px;
    background: none;
    border: none;
    cursor: pointer;
  }
}