.error {
  border: 2px solid #ff6565 !important;
}

.error-message {
  color: #ff6565;
  padding: 0.5em 0.2em;
  // height: 1em;
  // position: absolute;
  font-size: 1.2em;
}

.bookingorder-menu__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
}

.bookingorder-limit {
  max-width: 75px;
}
