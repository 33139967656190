.head-row-data {
    border-bottom: 2px solid #E5E4E4;

    .data-row-title {
        color: #000;
        font-weight: bold;
        font-family: 'Poppins';
    }
}

.box-card {
    .base-row-data-client {
        border-bottom: 1px solid #E5E4E4;
    }

    .circle-project-icon {
        width: 150px;
        margin-bottom: auto;
        height: 55px;
        border-radius: 100%;
        text-align: center;
    }

    .to-detail-data-client {
        color: #000;
        text-decoration: none;

        &:hover {
            text-decoration: underline !important;
        }
    }
}

.list-status {
    font-size: 20px;
    background: rgb(241, 241, 241);
    border-radius: 8px;
    width: 125px;
    height: 33px;
}

.btn-add-client-wrapper {
    position: fixed;
    right: 0;
    bottom: 0;

    .btn-add-client {
        border: none;
        border-radius: 100%;
        background: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &:hover {
            background: white;
        }
    }
}

.icon-project {
    width: 120px;
    margin: 15px auto;

    img {
        width: 100%;
        border-radius: 100%;
    }
}

.tabs-client-detail {
    .nav-item {
        .active {
            background-color: #fff !important;
            color: #6CBB07 !important;
        }
    }
}

.tabs-content {
    background-color: #ffffff00 !important;

    .bullet-card-transaction {
        margin: 0 5px;
        line-height: 20px;
        display: flex;
        justify-content: end;
    }
}

.list-api-credentials {
    list-style: none;
    font-family: 'poppins';
    font-weight: 400;
    padding-left: 0;
}