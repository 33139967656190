.helpdesk_cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(215px, 1fr));
  .helpdesk_cards__card {
    background: #FFFFFF;
    border-width: 2px;
    border-style: solid;
    border-color: black;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    .helpdesk_cards__card__title {
      // font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      color: #174D9D;
    }
    .helpdesk_cards__card__value {
      // font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 62px;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.helpdesk-category-tag {
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  padding: 5px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
}
.helpdesk-history-filter {
  width: 70%;
}
.helpdesk-history {
  max-height: 500px;
  overflow: auto;
  .helpdesk-history-no-data {
    padding: 200px 0;
    border-radius: 10px;
    border: 1px solid #B4B4B4;
  }
  .helpdesk-history-item {
    background: #FFFFFF;
    border: 1px solid #B4B4B4;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 10px;
    font-family: 'Poppins';
    .helpdesk-history-item__pre-title {
      font-style: normal;
      font-weight: 400;
      font-size: 8px;
      line-height: 12px;
  
      color: #000000;
    }
    .helpdesk-history-item__title {
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      
      color: #000000;
    }
    .helpdesk-history-item__desc {
      font-style: normal;
      font-weight: 300;
      font-size: 11px;
      line-height: 16px;
      
      color: #000000;
    }
    .helpdesk-history-item__status {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 12px;
  
      color: #000000;
    }
  }
}