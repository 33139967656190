.reusable-close-icon-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s;
}

.reusable-close-icon-wrapper:hover {
  background-color: #ebedf2;
}

.reusable-close-icon-wrapper:active {
  transform: scale(.7);
}