 .form-search {
     .form-label {
         display: none;
     }
 }

 .box-card {
     color: #000;
     border-radius: 6px;
     box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

     .text-desc {
         font-weight: 400;
         font-size: 15px;
     }
 }