.wrapper {
  font-family: 'Poppins';
  font-style: normal;
  color: #000000;

  .title {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
  }

  .desc {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
  }

  .right-desc {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    text-align: right;

    color: rgba(90, 90, 90, 0.63);
  }
}
.sipendar-table {
  position: inherit;
  margin-top: 100px;

  .item {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 15px;

    .content {
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: #000000;
    }
  }

  .main-row {
    background: #FFFFFF;
    padding: 15px;
    min-height: 70px;
    margin: 5px 0 5px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #000000;
  }

  .left-row {
    border-radius: 5px 0 0 5px;
  }

  .right-row {
    border-radius: 0 5px 5px 0;
  }
}
.sipendar-report-form {
  font-family: 'Poppins';
  font-style: normal;
  .title {
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    color: #000000;
  }
  .desc {
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #000000;

  }
}
.sipendar-button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #278DD7;
  border: none;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(120, 146, 129, 0.1);
  transition: all 0.3s;
  .icon {
    transition: all 0.3s;
    background: #278DD7;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 8px;
  }
}
.sipendar-button:hover {
  background-color: #278DD7;
  color: #FFFFFF;
  .icon {
    color: #278DD7;
    background-color: #FFFFFF;
  }
}