.preview-fullscreen{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
    display: none;
    opacity: 0;
    overflow: hidden;
}
.preview-fullscreen-active{
   display: inline !important;
   opacity: 1 !important;
}
.preview-frame{
   position: relative;
   top: 10px;
   left: 10px;
}
.preview-frame-top{
   margin-top: 40px;
   margin-right: 40px;
   display: flex;
   justify-content: flex-end;
   align-items: flex-start;
}
.preview-content{
   height: 100%;
   display: flex;
   justify-content: center;
}
.pan-container{
   align-items: unset!important;
   margin-top: 100px!important;
}